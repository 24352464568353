import { PropertyChangeEvent } from "@otto-ec/otto-components/common"
import { DataContainer, tracking } from "@otto-ec/tracking-bct"

export interface PasswordFieldTrackingProps {
    showPasswordTrackingEvent: DataContainer
    hidePasswordTrackingEvent: DataContainer
}

export class PasswordFieldTracking {
    private lastTrackedState?: boolean = undefined

    constructor(private readonly props: PasswordFieldTrackingProps) {}

    public trackUserInteractions(passwordField: HTMLOcAuthFieldV1Element) {
        passwordField.addEventListener("oc-property-change", this.onClickShowHidePasswordToggle)
    }

    private onClickShowHidePasswordToggle = (event: PropertyChangeEvent) => {
        if (!event.detail.passwordVisible) {
            return
        }

        /*                                                                                                 */
        /*                                                                                                */
        const newValue = event.detail.passwordVisible.current as boolean
        if (this.lastTrackedState !== newValue) {
            if (newValue) {
                this.trackUserActivity(this.props.showPasswordTrackingEvent)
            } else {
                this.trackUserActivity(this.props.hidePasswordTrackingEvent)
            }
            this.lastTrackedState = newValue
        }
    }

    private trackUserActivity(event: DataContainer) {
        tracking.submitEvent(event).catch((e: unknown) => {
            console.error(e)
        })
    }
}
